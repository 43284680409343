import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Price = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    // AMP: "Amp",
    // // MICAMP: "Microphone Amp",
    // INTRUMENT: "樂器"
    // // DRUM: "Drum set",
    // // KEYBOARD: "Keyboard",
    // // MIC: "Microphone"
  };

  const projectsData = [
    {
      title: "Price",
      projectInfo:
        "Price",
      thumbImage: "images/priceList.png",
      // sliderImages: [
      //   "images/projects/VID-20230807-WA0002.gif",
      //   "images/projects/VID-20230807-WA0002.gif",
      // ],
      categories: [filters.AMP],
    }
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="price"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              價錢
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              價錢
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
            
          </div>
          <div>
            
            <span className="img-fluid d-block portfolio-image" >
              <img
                className="img-fluid d-block"
                src="images/priceList.png"
                // style={{ width: 400, height: 400 }}
              />
            </span>
          
        </div>
        </div>
      </section>
      
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Price;

import React from "react";
import Typewriter from "typewriter-effect";
import videobg from "../videos/home.mp4";
import Contact from "./Contact";
import AboutUs from "./About";
import { Link } from "react-scroll";
import { useState } from "react";
const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />

        {/* ---------------image background------------------ */}
        <div
          className="hero-bg parallax"
          style={{ backgroundImage: 'url("images/intro-bg.jpg")' }}
        ></div>

        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <p className="text-7 fw-500 text-white mb-2 mb-md-3">
                  RawSound 觀塘 牛頭角 Band房
                </p>
                <p className="text-3 fw-500 text-white mb-2 mb-md-3">
                  RawSound Band Room
                </p>
                <h2 className="text-16 fw-600 text-white mb-2 mb-md-3">
                  <Typewriter
                    options={{
                      strings: [
                        // "Unlocking the raw potential of your music.",
                        "24小時自助Band房",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h2>

                <br></br>
                <br></br>
                <br></br>
                {/* <div className="position-relative d-flex text-center mb-5">
                  <p
                    className={
                      "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                      "text-white"
                    }
                  >
                    {" "}
                    Contact Us 歡迎查詢預約
                    <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
                  </p>
                </div> */}
                {/* Heading end*/}
                <div className="row gy-5">
                  <li>
                    <span className="fw-600 me-2 text-white">Whatsapp:</span>
                    <a href="https://wa.me/message/SZYUCQWDNRAMK1">46784167</a>
                  </li>
                  <li>
                    <span className="fw-600 me-2 text-white">Instagram:</span>
                    <a href="https://www.instagram.com/rawsoundmusic/">
                      @RawSoundMusic
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="fw-600 me-2 text-green"
                      smooth
                      duration={500}
                      style={{ cursor: "pointer" }}
                      activeClass="active"
                      spy
                      to="price"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsNavModalClose(true);
                      }}
                    >
                      價目表
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="fw-600 me-2 text-green"
                      smooth
                      duration={500}
                      style={{ cursor: "pointer" }}
                      activeClass="active"
                      spy
                      to="portfolio"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsNavModalClose(true);
                      }}
                    >
                      器材圖片
                    </Link>
                  </li>
                  <li>
                    <span className="fw-600 me-2 text-white">地址:</span>
                    <a href="https://maps.app.goo.gl/kU94iTDK6A9cn7BL8">
                      牛頭角 偉業街 美嘉工業大廈
                    </a>
                  </li>
                </div>
                <br></br>
              </div>
            </div>
          </div>

          <a
            href="#about"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("about");
            }}
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
